<template>
  <footer>
    <div class="buttonWrap">
      <button @click="handleLang('ko')" class="language-btn">한국어</button>
      <button @click="handleLang('en')" class="language-btn">ENGLISH</button>
    </div>
    <div>
       <!--횡성군청 전용 -->
      <div v-if="accountId=='hsg'">
        <ul>
          <li>횡성군청 토지재산과 공간정보팀 김현수</li>
          <li>{{ $t("footer-data-company-tel") }}: 033-340-2315</li>
          <li>{{ $t("footer-data-company-email") }}: : hyunsunim@korea.kr </li>
        </ul>
        <p style="margin-bottom: 20px;">Copyright © TEIXON. All Rights Reserved.</p>
        <p style="text-align: center;color: #ffffff;">
              {{ $t("login-info-available") }}
              {{ $t("login-info-available-illegally") }}
            </p>
        <div class="termsfooter" style="margin-top: 15px;">
          <ul>
            <li @click="showPrivacy(1)" >개인정보 처리방침</li>
            <li @click="showPrivacy(2)" >서비스 이용약관</li>
            <li @click="showPrivacy(3)" >위치기반 서비스 이용약관</li>
          </ul>
        </div>
      </div>
       <!--테스트 전용 -->
      <!-- <div v-else-if="accountId='dev'">
        <ul>
          <li>횡성군청 토지재산과 공간정보팀 김현수</li>
          <li>{{ $t("footer-data-company-tel") }}: 033-340-2315</li>
          <li>{{ $t("footer-data-company-email") }}: : hyunsunim@korea.kr </li>
        </ul>
        <p style="margin-bottom: 20px;">Copyright © TEIXON. All Rights Reserved.</p>
        <p style="text-align: center;color: #ffffff;">
              {{ $t("login-info-available") }}
              {{ $t("login-info-available-illegally") }}
            </p>
        <div class="termsfooter" style="margin-top: 15px;">
          <ul>
            <li @click="showPrivacy(1)" >개인정보 처리방침</li>
            <li @click="showPrivacy(2)" >서비스 이용약관</li>
            <li @click="showPrivacy(3)" >위치기반 서비스 이용약관</li>
          </ul>
        </div>
      </div> -->
      
      <!--테이슨 전용 -->
      <div v-else>
        <ul>
          <li>{{ $t("footer-data-company-name") }}</li>
          <li>{{ $t("footer-data-company-tel") }}: 02-468-1197</li>
          <li>{{ $t("footer-data-company-email") }}: support@teixon.com</li>
        </ul>
        <p style="margin-bottom: 20px;">Copyright © TEIXON. All Rights Reserved.</p>
        <p style="text-align: center;color: #ffffff;">
              {{ $t("login-info-available") }}
              {{ $t("login-info-available-illegally") }}
            </p>
        <div class="termsfooter" style="margin-top: 15px;">
          <ul>
            <li @click="showPrivacy(1)" >개인정보 처리방침</li>
            <li @click="showPrivacy(2)" >서비스 이용약관</li>
            <li @click="showPrivacy(3)" >위치기반 서비스 이용약관</li>
          </ul>
        </div>
      </div>
    </div>
    
    
  </footer>
</template>
<style>
footer{padding: 10px 0;position: absolute;bottom: 5px;}
.buttonWrap{border: 0 !important;}
.buttonWrap > .language-btn{margin: 0 5px;background: #ffffff !important;padding: 5px 15px !important;border-radius: 5px;border: 1px solid #ddd;}
.buttonWrap > .language-btn:hover{background: #ffffff75 !important;color: #fff;}
.termsfooter > ul > li{font-size: 1.3rem !important;}
.termsfooter > ul > li:hover{color: #c9c9c9 !important;}
</style>
<script>
export default {
  computed: {
    currentLang: {
      get() {
        return this.$store.state.store.currentLang;
      },
      set(value) {
        this.$store.commit("store/langChange", value);
      },
    },
  },
  props:{
    termsNum: {
      type: null
    }
  },
  data() {
    return {
      accountId:null,
    }
  },
  mounted() {
    this.accountId = this.$route.params.accountId;
  },
  methods: {
    showPrivacy(termsNum){
      window.open(
          `/${this.accountId}/TermsViewer/${termsNum}`,
          ``,
          "left=100,top=100,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no,status=no"
      );
    },
    handleLang(language) {
      this.$i18n.locale = language;
      this.$store.commit("store/langChange", language);
    },
  },
};
</script>
